<template>
    <div class="home">
        <div class="home-title">
            <el-card shadow="always">
                <el-row>
                    
                    <el-col :span="6" style="text-align: left;" class="hidden-md-and-down" >
                        <div class="greetings">您好,{{username}}</div>
                        <div class="weather">今天是：{{curdate}}</div>
                    </el-col>

                </el-row>
                
            </el-card>
            <el-row style="margin-top:10px" :gutter="20" v-if="ismerchant == 1">
                  <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                      <el-card shadow="never">
                        <template #header>
                          <div class="card-header">
                          用户基本信息
                          <!--<el-button type="text" size="mini"></el-button>-->
                          </div>
                        </template>
                        <div>
                          <el-form :model="merchantinfo" label-width="150px" label-position="left">
                            <el-form-item label="商户名称:">{{merchantinfo.Merchant}}</el-form-item>
                            <el-form-item label="联系人姓名:">{{merchantinfo.MerchantName}}</el-form-item>
                            <el-form-item label="联系电话:">{{merchantinfo.MerchantMobile}}</el-form-item>
                            <el-form-item label="结算比例:">{{merchantinfo.Rate/100}}%</el-form-item>
                          </el-form>
                        </div>
                      </el-card>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" >
                      <el-card shadow="never" class="">
                        <template #header>
                          <div class="card-header">
                            <span>美团参数</span>
                            <!--<el-button type="primary" @click="EditMode" size="mini" v-if="!editmode">编辑</el-button>-->
                            <div v-if="editmode">
                              <el-button type="success" @click="Save" size="mini" >保存</el-button>
                              <el-button type="info" @click="Cancel" size="mini">取消</el-button>
                            </div>
                            
                          </div>
                        </template>
                        <div>
                          <el-form :model="merchantinfo" label-width="150px" label-position="left">
                            <el-form-item label="appkey:">
                              <span v-if="!editmode">{{merchantinfo.AppKey}}</span>
                              <el-input size="small" v-if="editmode" :placeholder="merchantinfo.AppKey" v-model="merchantinfo.AppKey"></el-input>
                            </el-form-item>
                            <el-form-item label="appsecret:">
                              <span v-if="!editmode">{{merchantinfo.AppSecret}}</span>
                              <el-input size="small" v-if="editmode" :placeholder="merchantinfo.AppSecret" v-model="merchantinfo.AppSecret"></el-input>
                            </el-form-item>
                            <el-form-item label="session:">
                              <span v-if="!editmode">{{merchantinfo.Session}}</span>
                              <el-input size="small" v-if="editmode" :placeholder="merchantinfo.Session" v-model="merchantinfo.Session"></el-input>
                            </el-form-item>
                            <el-form-item label="授权:">
                              <el-button type="primary" @click="AuthMeituan" size="small" :disabled="editmode?true:false">点击跳转</el-button>
                            </el-form-item>
                          </el-form>
                        </div>
                      </el-card>
                  </el-col>
            </el-row>
            
                  
            
        </div>

    </div>
        
    
</template>

<script>
    
    //import {VueEcharts} from 'vue3-echarts'
    // import {revenueAnalysis,overview} from '@/api/serviceApi'
    import constant from "@/constant"
    import Cookies from 'js-cookie'
    import qs from "qs"

    export default {
        
        data(){
            return {


                editmode:false,
                

                username:'',
                expired: 0,
                abouttoexpired: 0,
                on: 0,
                info:[],

                merchantinfo:''

            }
        },

        methods:{
          AuthMeituan(){
            let merchant_id = this.merchantinfo.ID;
            let merchant_app_key = this.merchantinfo.AppKey;
            if(merchant_app_key){
                let url = "https://e.dianping.com/dz-open/merchant/auth?app_key=" + merchant_app_key + "&redirect_url=" + constant.redirect +"&state=" + merchant_id;
                window.open(url,"_blank");
            }else{
                this.$message.error("操作失败");
            }
          },
          Save(){
            this.axios.post(constant.savemerchant,qs.stringify(this.merchantinfo)
            ).then((response)=>{
              console.log(response.data);
              if(response.data == "OK"){
                this.$message.success("操作成功");
                this.editmode = false;
              }
            });
          },
          Cancel(){
            this.editmode = false;
          },
          EditMode(){
            this.editmode = true;
            return false;
          },

           GetStatisticInfo(){
              this.axios.get(constant.statisticinfo,{
                headers:{
                  "Content-Type": "application/json",
                }
              }).then((response)=>{ 
                console.log(response.data);
                this.info = response.data;
              })

           },
           GetMerchantinfo(){
              this.axios.get(constant.getmerchantinfo,{
                headers:{
                  "Content-Type": "application/json",
                },
                params:{
                  mid:this.mid
                }
              }).then((response)=>{
                console.log(response.data);
                this.merchantinfo = response.data;
              })

           }
        },

        created:function(){
            this.ismerchant = Cookies.get("IsMerchant");
            this.mid = Cookies.get("mid");

            //this.GetStatisticInfo();

            var dd = new Date();
            var year = dd.getFullYear();
            var month =
            dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
            var day = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
            this.curdate = year + "-" + month + "-" + day;

            const username = Cookies.get("name");
            this.username = username;
            console.log(Cookies.get("patharr"));

            this.GetMerchantinfo();

        }

    }
</script>
<style scoped>

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .contentblock{
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .card-info{
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .card-info-title{
      font-weight: 200;
      font-size: 20px;
    }
    .card-info-no{
      padding:5px;
      font-size: 36px;
      font-weight: 300;
    }
    .card-info-date{
      width:100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
    .info-block{
      margin-top:20px;
      
    }
    .home .home-title .title-msg {
                font-size: 20px;
                padding-bottom: 2rem;
    }
    .home .home-title .greetings {
                font-size: 18px;
                line-height: 50px;
                letter-spacing: .1em;
    }
    .home .home-title .weather {
                font-size: 14px;
                color: #999;
                line-height: 24px;
                letter-spacing: .1em;
    }
    .home .home-title .item {
                color: #808695;
                text-align: center;
                padding: 0.5em;
    }
    .home .home-title .item-text {
                font-weight: 700;
                font-size: 20px;
                color: #000000;
    }
    .home .home-card .card-text {
                font-size: 12px;
                font-weight: 400;
                color: #494b52;
                letter-spacing: .1em;
                padding-top: 0.4em;
    }
    .home .home-chart .satisfaction {
                padding-top: 0.2em;
                text-align: center;
    }
    .home .home-chart .satisfaction-number {
                font-weight: 700;
                font-size: 30px;
                color: #515a6e;
                line-height: 120px;
                text-align: center;
    }
    .home .home-chart .satisfaction-image {
                width: 60px;
                height: 60px;
                text-align: center;
    }
    .home .home-chart .satisfaction-msg {
                line-height: 35px;
                color: #808695;
                font-size: 14px;
                text-align: center;
    }
    .home .home-chart .satisfaction-ratio {
                line-height: 120px;
                color: #36c17b;
                font-size: 18px;
                text-align: center;
    }
    .home .home-chart .sale-item{
      border-radius: 0.2em;
                line-height: 3.2em;
                text-align: center;
                margin: 1em 0;
    }
    .home .home-chart .sale-item .item-image {
                    top: 0.6em;
                }
    .home .home-chart .sale-item .item-num {
                    height: 2.4em;
                    line-height: 2.4em;
                    color: white;
                    font-weight: bold;
                    font-size: 20px;
                    text-align: right;
                    padding-right: 1em;
                }
    .home {
        padding-bottom: 4em;
    }

    .content {
        
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        
    }
/**
 内边距
 */
.p4em{
  padding-right: 0.4em;
}

/**
 外边距
 */
.m120{
  margin-top: 1.2em;
}
.mb120{
  margin-bottom: 1.2em;
}

/**
 颜色
 */
.c-red{
  color: red;
}
.c-coral{
  color: coral;
}
.c-brown{
  color: brown;
}
.c-cyan{
  color: cyan;
}
.c-forestgreen{
  color: forestgreen;
  font-weight: bold;
}
.c-blue {
    color: #1C86EE
}
/**
 数字效果
 */
.num-effect{
  color: #494b52;
  font-size: 1.5em;
  font-weight: bold;
}

.f-weight{
  font-weight: bold;
}

.div-right{
  float: right;
}

.icon{
  width: 30px;
  height: 30px;
  right: 0.2em;
}
</style>
